/* Cards */
.dReLifecycle-container .collapsible-wrapper .card {
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.dReLifecycle-container .collapsible-wrapper .card-header {
  background: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 34px;
}

.dReLifecycle-container .collapsible-wrapper .card-content {
  background: #ffffff;
  padding: 24px 34px;
  flex: 1 1 0%;
}
