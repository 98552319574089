.dReLifecycle-container header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #0d1329;
}

.dReLifecycle-container header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dReLifecycle-container header .profile {
  color: #0d1329;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
}

.dReLifecycle-container header .profile .profile-avatar {
  display: flex;
  column-gap: 3px;
  align-items: center;
}
