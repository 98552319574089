#root {
  height: 100%;
  font-family: "Roobert", sans-serif;
}

/* Global styles */
.dReLifecycle-container {
  background: linear-gradient(0deg, rgba(34, 102, 98, 0.06), rgba(34, 102, 98, 0.06)),
    linear-gradient(180deg, #f1f8f7 0%, #e2f3e9 100%);
  min-height: 100%;
  padding-bottom: 50px;
  color: #2d313e;
}

.dReLifecycle-container h1,
.dReLifecycle-container h2,
.dReLifecycle-container h3,
.dReLifecycle-container h4,
.dReLifecycle-container p {
  margin: 0;
}

.dReLifecycle-container h1 {
  margin-left: 9px;
  font-size: 21px;
  color: #000000;
  font-weight: normal;
}

.dReLifecycle-container h2 {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 12px;
  color: #3f5f69;
}

.dReLifecycle-container h3 {
  font-weight: 600;
  font-size: 21px;
}

/* Content */
.dReLifecycle-container .content {
  margin: 25px auto;
  width: 95%;
  max-width: 1560px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-body {
  display: none;
  padding: 20px 20px 0 20px;
}

.dReLifecycle-container .collapsible-wrapper.open .collapsible-body {
  display: block;
  padding-bottom: 50px;
}

.dReLifecycle-container .collapsible-wrapper.open .collapsible-body .disclaimer-message {
  display: block;
  width: 63%;
  margin: 5px;
  padding: 0;
}
.dReLifecycle-container .collapsible-wrapper.open .collapsible-body .disclaimer-message p {
  font-size: 14px;
  color: #3f5f69;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-body .cards-first-row {
  display: grid;
  grid-template-columns: 45% auto 22%;
  grid-auto-flow: column;
  column-gap: 20px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-body .cards-first-row-pending {
  display: grid;
  grid-auto-flow: column;
  column-gap: 0;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-body .cards-first-row-pending > div:first-child {
  margin-right: 20px;
}
