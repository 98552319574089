/* Contract-document */
.dReLifecycle-container .contract-document {
}

/* Contract-document-pending */
.dReLifecycle-container .contract-document.contract-document-pending {
}

.dReLifecycle-container .contract-document button {
  width: 58px;
  height: 29px;
  background: #eaf2f0;
  border: none;
}

.dReLifecycle-container .contract-document.contract-document-pending button {
  width: 80px;
}

.dReLifecycle-container .contract-document .card-content .document-info {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  align-items: center;
  font-size: 17px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dReLifecycle-container .contract-document .card-content .document-info .document-date {
  min-width: 100px;
  margin: 0 5px;
}

.dReLifecycle-container .contract-document .card-content .document-info:last-child {
  border: none;
}
