/* Sign-transaction */
.dReLifecycle-container .sign-transaction {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin: 20px 0 0 0;
}

.dReLifecycle-container .sign-transaction button {
  width: 169px;
  height: 38px;
  background: #0d1329;
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
}

.dReLifecycle-container .sign-transaction p {
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  color: #3f5f69;
}
