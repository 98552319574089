/* Payment-summary */
.dReLifecycle-container .payment-summary {
}

/* Payment-summary-pending */
.dReLifecycle-container .payment-summary-pending {
  grid-column: 1;
  grid-row: 1 / 3;
}

.dReLifecycle-container .payment-summary h4,
.dReLifecycle-container .payment-summary-pending h4 {
  font-size: 13px;
  text-transform: uppercase;
  color: #6f8a90;
}

.dReLifecycle-container .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dReLifecycle-container .card-content .payment-card-flow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dReLifecycle-container .payment-total-wrapper {
  width: 60%;
  padding: 25px 0 0;
}

.dReLifecycle-container .payment-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dReLifecycle-container .payment-total .contract-subject-loss,
.dReLifecycle-container .payment-total .contract-total-limit {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.dReLifecycle-container .payment-total .contract-subject-loss h4 {
  color: #76bebe;
}

.dReLifecycle-container .payment-total .contract-subject-loss p {
  font-weight: 600;
  font-size: 24px;
  color: #0d1329;
}

.dReLifecycle-container .payment-total .contract-total-limit h4 {
  text-align: right;
}

.dReLifecycle-container .payment-total .contract-total-limit p {
  font-weight: 600;
  font-size: 17px;
  text-align: right;
  color: #0d1329;
}

.dReLifecycle-container .payment-per-limit {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
}

.dReLifecycle-container .payment-per-limit div {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 5px;
}

.dReLifecycle-container .payment-per-limit p {
  font-weight: 600;
  font-size: 17px;
}

/* Progress Bar */
.dReLifecycle-container .progress-bar__container {
  width: 100%;
  height: 25px;
  margin-top: 25px;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  will-change: transform;
  box-shadow: 0 0 5px #dbe1e0;
  background: #dbe1e0;
}

.dReLifecycle-container .progress-bar {
  position: absolute;
  height: 100%;
  width: 0;
  content: "";
  background-color: #91cbec;
  padding: 3px 10px;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-family: sans-serif;
}

.dReLifecycle-container .card-content p.note-p,
.dReLifecycle-container .payment-summary-pending .card-content p.note-p {
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
}

.dReLifecycle-container .payment-summary-pending .card-content .payment-limits {
  width: 50%;
}

.dReLifecycle-container .payment-summary-pending .card-content .payment-reinsurer-period {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

/* Table */
.dReLifecycle-container .payment-reinsurer-period .reinsurance-table-container {
  background: #fafafa;
  border-radius: 8px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.dReLifecycle-container .payment-reinsurer-period .reinsurance-period-table {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0 10px;
}

.dReLifecycle-container .payment-reinsurer-period .reinsurance-period-table div {
  padding: 0 15px;
}

.dReLifecycle-container .payment-reinsurer-period .reinsurance-period-table div p {
  align-self: left;
}

.dReLifecycle-container .payment-reinsurer-period .reinsurance-period-table:first-child {
  border-bottom: 1px solid #76bebe;
  text-transform: uppercase;
  color: #647c82;
  font-size: 13px;
  font-weight: 700;
  padding-bottom: 15px;
}

.dReLifecycle-container .payment-reinsurer-period .reinsurance-period-table .first-with-border {
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}

/* Paragraph */
.dReLifecycle-container .card-content .payment-reinsurer-period p:first-child {
  padding-top: 0;
}

.dReLifecycle-container .payment-reinsurer-period p {
  font-size: 15px;
  padding: 0;
  border: none;
}

/* Responsive */

@media only screen and (max-width: 1555px) {
  .dReLifecycle-container .payment-total {
    flex-direction: column;
    row-gap: 15px;
  }

  .dReLifecycle-container .payment-total .contract-total-limit h4,
  .dReLifecycle-container .payment-total .contract-total-limit p {
    text-align: left;
  }

  .dReLifecycle-container .payment-per-limit {
    justify-content: flex-start;
    row-gap: 25px;
  }
}
