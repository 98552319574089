.dReLifecycle-container .collapsible-wrapper .collapsible-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 12px;
  padding: 20px 30px;
  cursor: pointer;
  box-shadow: 0px 46.7182px 70.487px rgba(0, 0, 0, 0.02), 0px 19.5177px 29.4478px rgba(0, 0, 0, 0.0143771),
    0px 10.4351px 15.7442px rgba(0, 0, 0, 0.0119221), 0px 5.84984px 8.82607px rgba(0, 0, 0, 0.01),
    0px 3.1068px 4.68746px rgba(0, 0, 0, 0.00807786), 0px 1.29281px 1.95056px rgba(0, 0, 0, 0.00562291);

  font-size: 15px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .contract-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .contract-status h3 {
  width: 187px;
  font-size: 18px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .contract-status.pending h3 {
  color: #db8814;
}

/* Collapsible Arrow */
.dReLifecycle-container .collapsible-header .contract-status .chevron-open {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5.94595px rgba(0, 0, 0, 0.1), 0px 0px 5.94595px rgba(0, 0, 0, 0.1);
  border-radius: 60px;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-open.pending-chevron {
  background: #db8814;
  border: 3px solid #db8814;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-open.active-chevron {
  background: #76bebe;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-open.active-chevron svg,
.dReLifecycle-container .collapsible-header .contract-status .chevron-open.pending-chevron svg {
  transform: rotate(0deg);
  transition: all 0.5s;
}

/* Closed Arrow */
.dReLifecycle-container .collapsible-header .contract-status .chevron-close {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5.94595px rgba(0, 0, 0, 0.1), 0px 0px 5.94595px rgba(0, 0, 0, 0.1);
  border-radius: 60px;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.active-chevron svg,
.dReLifecycle-container .collapsible-header .contract-status .chevron-close.pending-chevron svg {
  transform: rotate(-90deg);
  transition: all 0.5s;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.active-chevron svg path {
  fill: #6f8a90;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.pending-chevron svg path {
  fill: #db8814;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.pending-chevron {
  background: #ffffff;
  border: 3px solid #db8814;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.active-chevron {
  background: #ffffff;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 5px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header p a {
  text-decoration: underline;
  color: #2d313e;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .header-date p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
